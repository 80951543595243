import {
	goodsSkuDetail,
	goodsSkuCategory,
	manjian,
	aftersale,
	modifyClicks,
	addGoodsbrowse,
	goodsSkuInfo,
	goodsQrcode,
	evaluateConfig
} from "@/api/goods/goods"
import {
	isCollect,
	addCollect,
	deleteCollect
} from "@/api/goods/goods_collect"
import {
	couponTypeList,
	couponReceive,
	goodsCoupon
} from "@/api/coupon"
import {
	bundlingList
} from "@/api/bundling"
import {
	goodsEvaluateList,
	goodsEvaluateCount
} from "@/api/goods/evaluate"
import {
	mapGetters
} from "vuex"
import CountDown from "vue2-countdown"
// import {
// 	shopIsSubscribe,
// 	addShopSubscribe,
// 	deleteShopSubscribe
// } from "@/api/shop";
import {
	getArea
} from "@/api/address";
import {
	shopServiceOpen
} from "@/api/website.js"
import {
	cartList
} from "@/api/goods/cart"
import { goodsCategoryInfo } from "@/api/goods/goodscategory"
export default {
	data: () => {
		return {
			id: 0,
			skuId: 0,
			loading: true,

			picZoomUrl: "",
			thumbPosition: 0,
			// สามารถเคลื่อนย้ายได้หรือไม่
			moveThumbLeft: false,
			moveThumbRight: false,

			// รายละเอียดสินค้า
			goodsSkuDetail: {
				video_url: ""
			},
			evaluate_show: false, //ข้อคิดเห็นจะแสดงขึ้นหรือไม่
			discountText: "การลดครั้งนี้จะจบใน",
			discountTimeMachine: {
				currentTime: 0,
				startTime: 0,
				endTime: 0
			},
			qrcode: "",
			specDisabled: false,
			specBtnRepeat: false, //ป้องกันการทําซ้ํา
			btnSwitch: false,

			// รายละเอียดร้านค้า
			shopInfo: {},
			whetherCollection: 0,
			score: 0,

			couponList: [], //รายการคูปอง
			couponBtnRepeat: false, //รับคูปองเพื่อป้องกันการส่งซ้ํา

			manjian: {}, //รายการกิจกรรมส่วนลดแบบเต็ม

			//ตีราคา
			currentPage: 1,
			pageSize: 10,
			total: 0,
			evaluaType: 0,//ประเภทของรีวิว
			evaluteCount:{},//จํานวนรีวิว
			goodsEvaluateList: [],
			//แพ็คเกจคอมโบ
			bundling: [{
				bundling_goods: {
					bl_name: "",
					sku_image: ""
				}
			}],
			service: null,
			service_is_display:'',//การรับประกันหลังการขาย
			number: 1,
			tabName: "detail",
			tabBundling: "",
			playerOptions: {
				playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // ความเร็วในการเล่นที่เลือกได้
				autoplay: false, // ถ้าใช่true,การเล่นจะเริ่มขึ้นเมื่อเบราว์เซอร์พร้อม。
				muted: false, // เสียงใด ๆ จะถูกกําจัดโดยค่าเริ่มต้น。
				loop: false, // จะรีสตาร์ททันทีที่วิดีโอจบลงหรือไม่。
				preload: "auto", // ขอแนะนําให้เบราว์เซอร์อยู่ใน<video>ข้อมูลวิดีโอควรเริ่มดาวน์โหลดหลังจากโหลดองค์ประกอบแล้วหรือไม่。autoเบราว์เซอร์เลือกพฤติกรรมที่ดีที่สุด,เริ่มโหลดวิดีโอทันที（หากได้รับการสนับสนุนโดยเบราว์เซอร์）
				language: "en-us",
				aspectRatio: "16:9", // ทําให้เครื่องเล่นอยู่ในโหมดราบรื่น，และค่าจะถูกใช้เมื่อคํานวณขนาดไดนามิกของผู้เล่น。ค่าควรแสดงถึงมาตราส่วน - ตัวเลขสองตัวคั่นด้วยเครื่องหมายทวิภาค（เช่น"16:9"หรือ"4:3"）
				fluid: true, // ขณะtrueเวลา，Video.js playerจะมีขนาดของเหลว。ในคําอื่น ๆ，มันจะปรับขนาดตามสัดส่วนเพื่อให้พอดีกับภาชนะ。
				sources: [{
					type: "video/mp4", // ประเภท
					src: "" // urlที่อยู่
				}],
				poster: "", // ที่อยู่ปก
				notSupportedMessage: "วิดีโอนี้ไม่พร้อมใช้งานในขณะนี้，โปรดลองอีกครั้งในภายหลัง", // อนุญาตให้ใช้การแทนที่ได้Video.jsข้อความเริ่มต้นจะแสดงขึ้นเมื่อไม่สามารถเล่นแหล่งสื่อได้。
				controlBar: {
					timeDivider: true, // ตัวคั่นสําหรับเวลาและระยะเวลาปัจจุบัน
					durationDisplay: true, // แสดงระยะเวลา
					remainingTimeDisplay: true, // จะแสดงคุณสมบัติ Time Remaining หรือไม่
					fullscreenToggle: true // แสดงปุ่มเต็มหน้าจอหรือไม่
				}
			},
			switchMedia: "img",

			// ไม่ว่าจะให้ความสนใจกับร้านค้าหรือไม่
			hasFollow: false,
			
			// การกําหนดค่าส่วนบริการลูกค้า
			kefuConfig: {
				system: '',
				open_pc: '',
				open_url: ''
			},
cator: {},
  filters: {
                site_id: 0,
                category_id: 0,
                category_level: 0,
                brand_id: 0,
                min_price: "",
                max_price: "",
                order: "",
                sort: "desc",
                coupon: 0
            },
			// จังหวัด เมือง และมณฑล
			provinceArr: {},
			cityArr: {},
			districtArr: {},
first_index:0,
catewords:'',
c1:'',
c2:'',
			two_index: 0,
			first_child_list: [],
			first_index_active: 0,
			// จังหวัด เมือง และมณฑล id
			currTabAddres: "province",
			hideRegion: false,
			selectedAddress: {},
			service_list: [], //สินค้าและบริการ
			cartListNum:0,//จํานวนสินค้าที่อยู่ภายใต้รายการตะกร้าสินค้าในปัจจุบัน
			listNum:false,//ขีดจํากัดการซื้อคือ
			shopNum:""
		}
	},
	components: {
		CountDown
	},
	metaInfo() {
        return {
            meta: [
                { name: "description", content: this.goodsSkuDetail.goods_name || "" },
                { name: "keywords", content: this.goodsSkuDetail.keywords || ""}
            ]
        }
    },
	created() {
		this.skuId = this.$route.params.pathMatch
		this.getGoodsSkuDetail()
		
	},
	computed: {
		...mapGetters(["token", "siteInfo", "defaultHeadImage", "defaultShopImage", "defaultGoodsImage", "addonIsExit",
			'locationRegion'
		])
	},
	watch: {
		$route: function(curr) {
			this.skuId = curr.params.pathMatch
			this.getGoodsSkuDetail()
		}
	},
	methods: {

		shopServiceOpen(){
			shopServiceOpen().then((res) => {
				if (res.code == 0) {
					this.kefuConfig = res.data;
				}
			})
		},
		service_link() {
			if (this.token) {
				this.$refs.servicerMessage.show();
			} else {
				this.$notify({
					message: "คุณยังไม่ได้เข้าสู่ระบบ",
					title: "คำเตือน",type: "warning"
				})
			}
		},
		tabChange(tab, event) {
			
			
			
		},
		bundlingChange(tab, event) {},
		
		
		
		getGoodsSkuCatInfo() {
		goodsSkuCategory({
					sku_id: this.skuId
				})
				.then(res => {
					let data = res.data
					const myArray = data.category_id.split(",");
					goodsCategoryInfo({
					    category_id: myArray[1]
					}).then(res => {
				console.log(res,'ค้นหาหมวดหมู่ผลิตภัณฑ์')
                if (res.code == 0 && res.data) {
                    this.catewords = res.data.category_full_name
					  this.c1 = res.data.category_id
					  //this.c2 = res.data.category_id
				
                }
                
            }).catch(err => {
					
					})
					
					
				})
		},
		
		
		getGoodsSkuDetail() {
			this.getGoodsSkuCatInfo()
			
			goodsSkuDetail({
					sku_id: this.skuId
				})
				.then(res => {
					let data = res.data
					if (data.goods_sku_detail != null) {
						this.goodsSkuDetail = data.goods_sku_detail
						this.service_list = data.goods_sku_detail.goods_service
						// this.shopInfo = data.shop_info
						// let num = (Number(this.shopInfo.shop_desccredit) + Number(this.shopInfo.shop_servicecredit) + Number(this.shopInfo
						// 	.shop_deliverycredit)) / 3
						// this.score = Number(num.toFixed(1));
						this.id = this.goodsSkuDetail.goods_id

						if (this.skuId == 0) this.skuId = this.goodsSkuDetail.sku_id

						if (this.goodsSkuDetail.sku_images) this.goodsSkuDetail.sku_images = this.goodsSkuDetail.sku_images.split(",");
						else this.goodsSkuDetail.sku_images = [];

						// รวมภาพหลักเมื่อมีข้อกําหนดหลายประการ
						if (this.goodsSkuDetail.goods_spec_format && this.goodsSkuDetail.goods_image) {
							this.goodsSkuDetail.goods_image = this.goodsSkuDetail.goods_image.split(",");
							this.master_img = this.goodsSkuDetail.goods_image
							this.goodsSkuDetail.sku_images = this.goodsSkuDetail.sku_images.concat(this.goodsSkuDetail.goods_image);
						}

						//สื่อ
						if (this.goodsSkuDetail.video_url) {
							this.switchMedia = "video"
							this.playerOptions.poster = img(this.goodsSkuDetail.sku_images[0])
							this.playerOptions.sources[0].src = img(this.goodsSkuDetail.video_url)
						}

						this.picZoomUrl = this.goodsSkuDetail.sku_images[0]

						this.goodsSkuDetail.unit = this.goodsSkuDetail.unit || "รายการ"

						// รายการปัจจุบันSKUสเปค
						if (this.goodsSkuDetail.sku_spec_format) this.goodsSkuDetail.sku_spec_format = JSON.parse(this.goodsSkuDetail.sku_spec_format)

						// แอตทริบิวต์ผลิตภัณฑ์
						if (this.goodsSkuDetail.goods_attr_format) {
							let goods_attr_format = JSON.parse(this.goodsSkuDetail.goods_attr_format);
							this.goodsSkuDetail.goods_attr_format = this.$util.unique(goods_attr_format, "attr_id");
							for (var i = 0; i < this.goodsSkuDetail.goods_attr_format.length; i++) {
								for (var j = 0; j < goods_attr_format.length; j++) {
									if (this.goodsSkuDetail.goods_attr_format[i].attr_id == goods_attr_format[j].attr_id && this.goodsSkuDetail.goods_attr_format[
											i].attr_value_id != goods_attr_format[j].attr_value_id) {
										this.goodsSkuDetail.goods_attr_format[i].attr_value_name += "、" + goods_attr_format[j].attr_value_name;
									}
								}
							}
						}

						// สินค้าSKUรูปแบบ
						if (this.goodsSkuDetail.goods_spec_format) this.goodsSkuDetail.goods_spec_format = JSON.parse(this.goodsSkuDetail
							.goods_spec_format)

						window.document.title = `${this.goodsSkuDetail.sku_name} - ${this.siteInfo.site_name}`

						// ส่วนลดเวลา จํากัด
						if (this.goodsSkuDetail.promotion_type == 1 && this.addonIsExit.discount) {
							//ตรวจจับการนับถอยหลัง
							if (this.goodsSkuDetail.end_time - res.timestamp > 0) {
								this.discountTimeMachine = {
									currentTime: res.timestamp,
									startTime: res.timestamp,
									endTime: this.goodsSkuDetail.end_time
								}
							} else {
								this.goodsSkuDetail.promotion_type = 0
							}
						}
						
						this.shopServiceOpen()
						this.getGoodsEvaluate()
						this.goodsEvaluCount()
						//this.getGoodsSkuCatInfo()
						this.loading = false
						
					} else {
						this.$router.push("/")
					}
				})
				.then(res => {
					if (this.token != "") {
						this.getWhetherCollection()
						// this.isFollow()
					}
					if (this.addonIsExit.manjian) this.getManjian()
					if (this.addonIsExit.coupon) this.getCoupon()
					if (this.addonIsExit.bundling) this.getBundling()
					this.getAftersale()
					this.modifyGoodsInfo()
					this.getEvaluateConfig()
					this.getGoodsQrcode()
					this.getAddress('province', null, true);
					if (!this.locationRegion) {

						this.$store.commit("app/SET_LOCATION_REGION", {
							"level_1": {
								"id": 110000,
								"pid": 0,
								"name": "จังหวัด",
								"shortname": "จังหวัด",
								"longitude": "116.40529",
								"latitude": "39.904987",
								"level": 1,
								"sort": 1,
								"status": 1,
								"default_data": 1
							},
							"level_2": {
								"id": 110100,
								"pid": 110000,
								"name": "เขต",
								"shortname": "เขต",
								"longitude": "116.40529",
								"latitude": "39.904987",
								"level": 2,
								"sort": 1,
								"status": 1,
								"default_data": 1
							},
							"level_3": {
								"id": 110101,
								"pid": 110100,
								"name": "โซน",
								"shortname": "โซน",
								"longitude": "116.418755",
								"latitude": "39.917545",
								"level": 3,
								"sort": 3,
								"status": 1,
								"default_data": 1
							},
							master_img: []
						});
					}

					this.selectedAddress = this.locationRegion;
					this.provinceId = this.selectedAddress.level_1.id
					this.getAddress('city', null, true, () => {
						this.cityId = this.selectedAddress.level_2.id
						if (this.cityId) this.getAddress('district', null, true)
					});
				})
				.catch(res => {
					this.loading = false
					this.$router.push("/")
				})
		},
		changeThumbImg(tag) {
			if (this.goodsSkuDetail.sku_images.length < 5) return
			let page = this.goodsSkuDetail.sku_images.length % 5 // ปริมาณที่มองเห็นได้4ชิ้น
			let position = 68.8
			if (page == 0) page = this.goodsSkuDetail.sku_images.length - 5 // ปริมาณที่มองเห็นได้4ชิ้น
			else if (page != 0 && page != 1 && page < 2) return

			if (tag == "prev") {
				if (this.thumbPosition != 0 && Math.round(this.thumbPosition, 2) != position) {
					this.thumbPosition += position
					// this.moveThumbLeft = true;
				} else {
					// this.moveThumbLeft = false;
				}
			} else if (tag == "next") {
				if (Math.round(this.thumbPosition, 2) != -Math.round(position * page, 2)) {
					this.thumbPosition -= position
					// this.moveThumbRight = true;
				} else {
					// this.moveThumbRight = false;
				}
			}
		},
		//รับว่าผู้ใช้กําลังติดตามหรือไม่
		getWhetherCollection() {
			isCollect({
				goods_id: this.goodsSkuDetail.goods_id
			}).then(res => {
				this.whetherCollection = res.data
			})
		},
		editCollection() {


			//ไม่ติดตามเพิ่มการติดตาม
			if (this.whetherCollection == 0) {
				addCollect({
						sku_id: this.skuId,
						goods_id: this.goodsSkuDetail.goods_id
					}).then(res => {
						var data = res.data
						if (data > 0) {
							this.whetherCollection = 1
							this.goodsSkuDetail.collect_num++
						}
					})
					.catch(err => {
						if (err.message == 'คุณยังไม่ได้เข้าสู่ระบบ，กรุณาเข้าสู่ระบบก่อน') {
							this.$router.push('/login');
						}
						this.btnSwitch = false
					})
			} else {
				//ติดตามเลิกติดตาม
				deleteCollect({
						goods_id: this.goodsSkuDetail.goods_id
					}).then(res => {
						var data = res.data
						if (data > 0) {
							this.whetherCollection = 0
							this.goodsSkuDetail.collect_num--
						}
					})
					.catch(err => {
						if (err.message == 'คุณยังไม่ได้เข้าสู่ระบบ，กรุณาเข้าสู่ระบบก่อน') {
							this.$router.push('/login');
						}
						this.btnSwitch = false
					})
			}
		},
		//รับข้อมูลส่วนลดทั้งหมด
		getManjian() {
			manjian({
				goods_id: this.goodsSkuDetail.goods_id,
				site_id: this.goodsSkuDetail.site_id
			}).then(res => {
				let data = res.data
				if (data) {
					this.manjian = data;
					let limit = data.type == 0 ? ' บาท' : 'รายการ';
					Object.keys(data.rule_json).forEach((key) => {
						var item = data.rule_json[key];
						if(item.coupon_data){
							for(var i=0;i<item.coupon_data.length;i++){
								item.coupon_data[i].coupon_num = item.coupon_num[i]
							}
						}
						
						// ลบเต็ม
						if (item.discount_money != undefined) {
							if (this.manjian.manjian == undefined) {
								this.manjian.manjian = 'ซื้อครบ' + item.limit + limit + 'ลด ' + item.discount_money + ' บาท ';
							} else {
								this.manjian.manjian += 'หรือ ซื้อครบ' + item.limit + limit + 'ลด ' + item.discount_money + ' บาท ';
							}
						}
						// ส่งเต็ม
						if (item.point != undefined || item.coupon != undefined) {
							let text = '';
							if (item.point != undefined) {
								text = ' ได้รับ' + item.point + 'คะแนน';
							}
							if (item.coupon != undefined && item.coupon_data != undefined) {
								item.coupon_data.forEach((couponItem, couponIndex) => {
									if (couponItem.type == 'discount') {
										if (text == '') text = 'ให้'+ item.coupon_num[couponIndex] +'แผ่น' + parseFloat(couponItem.discount) + 'คูปองส่วนลด';
										else text += '、ให้'+ item.coupon_num[couponIndex] +'แผ่น' + parseFloat(couponItem.discount) + 'คูปองส่วนลด';
									} else {
										if (text == '') text = 'ให้'+ item.coupon_num[couponIndex] +'แผ่น' + parseFloat(couponItem.money) + 'คูปองเมตา';
										else text += '、ให้'+ item.coupon_num[couponIndex] +'แผ่น' + parseFloat(couponItem.money) + 'คูปองเมตา';
									}
								})
							}
							if (this.manjian.mansong == undefined) {
								this.manjian.mansong = 'ซื้อครบ' + item.limit + limit + text;
							} else {
								this.manjian.mansong += 'หรือ ' + 'ซื้อครบ' + item.limit + limit + text;
							}
						}
						// จัดส่งฟรี
						if (item.free_shipping != undefined) {
							if (this.manjian.free_shipping == undefined) {
								this.manjian.free_shipping = 'ซื้อครบ' + item.limit + limit + 'จัดส่งฟรี';
							} else {
								this.manjian.free_shipping += 'หรือ ซื้อครบ' + item.limit + limit + 'จัดส่งฟรี';
							}
						}
					})
				}
			})
		},
		// รับคูปอง
		getCoupon() {
			goodsCoupon({
				goods_id: this.goodsSkuDetail.goods_id
			}).then(res => {
				let data = res.data
				if (data) {
					this.couponList = []
					for (let i = 0; i < data.length; i++) {
						if (i > 4) break
						this.couponList.push(data[i])
					}
				}
			})
		},
		// รับคูปอง
		receiveCoupon(couponTypeId) {
			if (this.couponBtnRepeat) return
			this.couponBtnRepeat = true

			couponReceive({
					coupon_type_id: couponTypeId,
					site_id: this.goodsSkuDetail.site_id,
					get_type: 2 //วิธีรับ:1ใบ สั่ง2.รับโดยตรง3.การรับสินค้าจากกิจกรรม
				})
				.then(res => {
					var data = res.data
					let msg = res.message
					if (res.code == 0) {
						msg = "เรียกร้องความสําเร็จ"
					}
					this.$notify({
						message: msg,
						title: 'เรียบร้อยแล้ว',type: "success"
					})
					this.couponBtnRepeat = false
				})
				.catch(res => {
					this.couponBtnRepeat = false
				})
		},
		// แพ็คเกจคอมโบ
		getBundling() {
			bundlingList({
				sku_id: this.skuId
			}).then(res => {
				if (res.data && res.data.length) {
					this.bundling = res.data
					this.tabBundling = "bundling_" + this.bundling[0].bl_id
					for (var i = 0; i < this.bundling.length; i++) {
						for (var j = 0; j < this.bundling[i].bundling_goods.length; j++) {
							if (this.bundling[i].bundling_goods[j].sku_id == this.skuId) {
								this.bundling[i].bundling_goods.splice(j, 1)
							}
						}
					}
				}
			})
		},
		//สลับรีวิว
		evaluationType(value){
			this.evaluaType = value
			this.getGoodsEvaluate()
		},
		// การรับประกันหลังการขาย
		getAftersale() {
			aftersale({}).then(res => {
				if (res.code == 0 && res.data) {
					this.service_is_display = res.data
					let data = res.data.content
					if (res.data.content) this.service = res.data
				}
			})
		},
		//อัปเดตรายชื่อของคุณ
		modifyGoodsInfo() {
			//อัปเดตการคลิกผลิตภัณฑ์
			modifyClicks({
				sku_id: this.skuId,
				site_id: this.goodsSkuDetail.site_id
			})

			//เพิ่มรอยเท้า
			addGoodsbrowse({
				sku_id: this.skuId,
				goods_id: this.goodsSkuDetail.goods_id
			})
		},
		// รหัส QR สําหรับสินค้า
		getGoodsQrcode() {
			goodsQrcode({
				sku_id: this.skuId
			}).then(res => {
				let data = res.data
				if (data.path.h5.img) this.qrcode = img(data.path.h5.img)
			})
		},
		getEvaluateConfig() {
			evaluateConfig()
				.then(res => {
					if (res.code == 0) {
						var data = res.data;
						this.evaluateConfig = data;
						if (this.evaluateConfig.evaluate_show == 1) {
							//รีวิวสินค้า
							this.evaluate_show = true
							this.getGoodsEvaluate();
						}
					}
				})
		},
		//จํานวนรีวิวสินค้า
		goodsEvaluCount(){
			goodsEvaluateCount({
				goods_id: this.id,
			}).then(res =>{
				if(res.code == 0 && res.data){
					this.evaluteCount = res.data
				}
			})
		},
		// รายการรีวิวสินค้า
		getGoodsEvaluate() {
			goodsEvaluateList({
				page: this.currentPage,
				page_size: this.pageSize,
				goods_id: this.id,
				explain_type: this.evaluaType == 0 ? '' : this.evaluaType
			}).then(res => {
				let list = []
				let msg = res.message
				if (res.code == 0 && res.data) {
					list = res.data.list
					this.total = res.data.count
				}

				for (var i = 0; i < list.length; i++) {
					// 1สรรเสริญ2คะแนนปานกลาง3ความคิดเห็นที่ไม่ดี
					if (list[i].explain_type == 1) {
						list[i].star = 5
					} else if (list[i].explain_type == 2) {
						list[i].star = 3
					} else if (list[i].explain_type == 3) {
						list[i].star = 1
					}
					if (list[i].images) {
						list[i].images = list[i].images.split(",")
						list[i].imagesFormat = []
						for (var k = 0; k < list[i].images.length; k++) {
							list[i].imagesFormat.push(img(list[i].images[k]))
						}
					}

					if (list[i].again_images) {
						list[i].again_images = list[i].again_images.split(",")
						list[i].againImagesFormat = []
						for (var j = 0; j < list[i].again_images.length; j++) {
							list[i].againImagesFormat.push(img(list[i].again_images[j]))
						}
					}
					if (list[i].is_anonymous == 1) list[i].member_name = list[i].member_name.replace(list[i].member_name.substring(1,
						list[i].member_name.length - 1), "***")
				}
				this.goodsEvaluateList = list
			})
		},
		// โหลดรูปภาพไม่สําเร็จ
		imageErrorEvaluate(index) {
			this.goodsEvaluateList[index].member_headimg = this.defaultHeadImage
		},
		handlePageSizeChange(size) {
			this.pageSize = size
			this.getGoodsEvaluate()
		},
		handleCurrentPageChange(page) {
			this.currentPage = page
			this.getGoodsEvaluate()
		},
		changeSpec(skuId, spec_id) {
			if (this.specDisabled) return
			this.specBtnRepeat = false
			this.skuId = skuId
			// ล้างส่วนที่เลือก
			for (var i = 0; i < this.goodsSkuDetail.goods_spec_format.length; i++) {
				var sku = this.goodsSkuDetail.goods_spec_format[i]
				for (var j = 0; j < sku.value.length; j++) {
					// ไม่รวมค่าข้อมูลจําเพาะของการคลิกปัจจุบัน
					if (spec_id == this.goodsSkuDetail.goods_spec_format[i].value[j].spec_id) {
						this.goodsSkuDetail.goods_spec_format[i].value[j].selected = false
					}
				}
			}

			goodsSkuInfo({
				sku_id: this.skuId
			}).then(res => {
				let data = res.data
				if (data != null) {
					data.sku_images = data.sku_images.split(",")
					this.picZoomUrl = data.sku_images[0]
					this.playerOptions.poster = img(data.sku_image)
					if(data.sku_images == ""){
						data.sku_images = this.master_img;
						this.picZoomUrl = data.sku_images[0]
						this.playerOptions.poster = img(data.sku_image)
					}else{
						data.sku_images = data.sku_images.concat(this.master_img);
					}
					// รายการปัจจุบันSKUสเปค
					if (data.sku_spec_format) data.sku_spec_format = JSON.parse(data.sku_spec_format)

					// สินค้าSKUรูปแบบ
					if (data.goods_spec_format) data.goods_spec_format = JSON.parse(data.goods_spec_format)
					
					if (data.goods_attr_format) data.goods_attr_format = JSON.parse(data.goods_attr_format)
					this.keyInput(true)

					// ส่วนลดเวลา จํากัด
					if (data.promotion_type == 1) {
						this.discountTimeMachine = {
							currentTime: res.timestamp,
							startTime: res.timestamp,
							endTime: data.end_time
						}
					}
					this.specBtnRepeat = false
					Object.assign(this.goodsSkuDetail, data)
				} else {
					this.$router.push("/")
				}
			})
		},
		changeNum(tag) {


			if (this.goodsSkuDetail.stock == 0) return
			var stock = this.goodsSkuDetail.stock
			var min = 1

			if (tag == "+") {
				// เพิ่ม
				if (this.number < stock) {
					this.number++
				} else {
					return
				}
			} else if (tag == "-") {
				// ลบ
				if (this.number > min) {
					this.number -= 1
				} else {
					return
				}
			}

			this.purchase()
		},
		// กําหนดว่าจะจํากัดการซื้อหรือไม่
		purchase(){
			if(this.goodsSkuDetail.is_limit==1){
				if(this.goodsSkuDetail.limit_type==1){
					if(this.number>this.goodsSkuDetail.max_buy){
						this.$notify({
							message:'สินค้าจะซื้อมากที่สุด' + this.goodsSkuDetail.max_buy+'รายการ',
							title: 'คำเตือน',type: 'warning'
						});
						this.number=this.goodsSkuDetail.max_buy
					}
				}else if(this.goodsSkuDetail.limit_type==2){
					this.shopNum=this.goodsSkuDetail.max_buy-this.goodsSkuDetail.purchased_num
					console.log(this.shopNum)
					var title=''
					if(this.goodsSkuDetail.purchased_num==0){
						title='รายการนี้จํากัดเฉพาะแต่ละคนเท่านั้น' + this.goodsSkuDetail.max_buy+'รายการ'
					}else if(this.goodsSkuDetail.purchased_num>0){
						title='รายการนี้จํากัดเฉพาะแต่ละคนเท่านั้น' + this.goodsSkuDetail.max_buy+'รายการ，คุณได้ซื้อ'+this.goodsSkuDetail.purchased_num+'รายการ'
					}

					if(this.number>this.shopNum){
						console.log("ไปที่เพิ่ม")
						this.$notify({
							message:title,
							title: 'คำเตือน',type: 'warning'
						});
						this.number=this.shopNum
					}
				}
			}	
		},

		//ป้อนปริมาณ
		keyInput() {

			var stock = this.goodsSkuDetail.stock
			// สินค้าคงคลังคือ0
			if (this.goodsSkuDetail.stock == 0) {
				this.number = 0
				return
			}
			// ป้องกันการโมฆะ
			if (this.number == 0 || this.number == '') this.number = 1

			var re = /^\d+$/
			if (re.test(parseInt(this.number))) {
				if (this.number > stock) {
					this.number = stock
				}
				this.number = parseInt(this.number)
			} else {
				this.number = 1
			}

			this.purchase()
		},
		// เล่นการโทรกลับ
		onPlayerPlay(player) {},
		// หยุดการโทรกลับชั่วคราว
		onPlayerPause(player) {},
		// โทรกลับหลังจากเล่นวิดีโอแล้ว
		onPlayerEnded(player) {},
		// DOMธาตุreadyStateการเปลี่ยนแปลงนี้ทําให้การเล่นหยุดลง
		onPlayerWaiting(player) {},
		// การโทรกลับเริ่มเล่นแล้ว
		onPlayerPlaying(player) {},
		// จะเริ่มทํางานเมื่อเครื่องเล่นดาวน์โหลดข้อมูลที่ตําแหน่งการเล่นปัจจุบัน
		onPlayerLoadeddata(player) {},
		// ทริกเกอร์เมื่อตําแหน่งการเล่นปัจจุบันเปลี่ยนไป。
		onPlayerTimeupdate(player) {},
		//สื่อของreadyStateเพื่อHAVE_FUTURE_DATAหรือสูงกว่า
		onPlayerCanplay(player) {},
		//สื่อของreadyStateเพื่อHAVE_ENOUGH_DATAหรือสูงกว่า。นั่นหมายความว่าไฟล์สื่อทั้งหมดสามารถเล่นได้โดยไม่มีการบัฟเฟอร์。
		onPlayerCanplaythrough(player) {},
		//สถานะการเล่นเปลี่ยนการเรียกกลับ
		playerStateChanged(playerCurrentState) {},
		//ผูกผู้ฟังกับสถานะความพร้อมของส่วนประกอบ。ความแตกต่างกับผู้ฟังเหตุการณ์คือ，ถ้าreadyเหตุการณ์ได้เกิดขึ้นแล้ว，มันเรียกฟังก์ชั่นทันที。。
		playerReadied(player) {},
		// หยิบใส่ตะกร้า
		joinCart() {
			//จํานวนการแก้ไข
			
			if (this.goodsSkuDetail.stock == 0) {
				this.$notify({
					message: "สินค้าขายหมดแล้ว",
					title: "คำเตือน",type: "warning"
				})
				return
			}

			if (this.number.length == 0 || this.number == 0) {
				this.$notify({
					message: "ปริมาณการซื้อไม่สามารถเป็นได้0",
					title: "คำเตือน",type: "warning"
				})
				return
			}

			if (this.btnSwitch) return
			this.btnSwitch = true

			this.$store.dispatch("cart/add_to_cart", {
				site_id: this.goodsSkuDetail.site_id,
				sku_id: this.goodsSkuDetail.sku_id,
				num: this.number
			}).then(res => {
				console.log("การเพิ่มประสบความสําเร็จ")
				var data = res.data
				if (data > 0) {
					this.$notify({
						message: "เพิ่มลงในตะกร้าแล้ว",
						title: 'เรียบร้อยแล้ว',type: "success"
					})
				}
				this.btnSwitch = false
			})
			.catch(err => {
				if (err.message == 'คุณยังไม่ได้เข้าสู่ระบบ，กรุณาเข้าสู่ระบบก่อน') {
					this.$router.push('/login');
				} else {
					this.$notify.error({title: 'Error',message: err.message});
				}
				this.btnSwitch = false
			})
			console.log(this.cartListNum)
		},
		// ซื้อตอนนี้
		buyNow() {

			if (this.goodsSkuDetail.stock == 0) {
				this.$notify({
					message: "สินค้าขายหมดแล้ว",
					title: "คำเตือน",type: "warning"
				})
				return
			}

			if (this.number.length == 0 || this.number == 0) {
				this.$notify({
					message: "ปริมาณการซื้อไม่สามารถเป็นได้0",
					title: "คำเตือน",type: "warning"
				})
				return
			}

			if (parseInt(this.number) + parseInt(this.goodsSkuDetail.purchased_num) > this.goodsSkuDetail.max_buy && this.goodsSkuDetail
				.max_buy != 0) {
				let _newNum = parseInt(this.goodsSkuDetail.max_buy) - parseInt(this.goodsSkuDetail.purchased_num)
				this.$notify({
					message: 'ข้อจํากัดของผลิตภัณฑ์' + this.goodsSkuDetail.max_buy + 'รายการ，ซื้อตอนนี้' + this.goodsSkuDetail.purchased_num + 'รายการ,นอกจากนี้ยังสามารถซื้อได้' +
						_newNum + 'รายการ',
					title: "คำเตือน",type: "warning"
				})
				return
			}

			var data = {
				sku_id: this.skuId,
				num: this.number
			}

			this.$store.dispatch("order/setOrderCreateData", data)
			this.$router.push({
				path: "/payment"
			})
		},
		countDownS_cb() {},
		countDownE_cb() {
			this.discountText = "กิจกรรมได้สิ้นสุดลงแล้ว"
		},
		//ไม่ว่าจะดูแล
		// isFollow() {
		// 	shopIsSubscribe({
		// 		site_id: this.goodsSkuDetail.site_id
		// 	}).then(res => {
		// 		if (res.code == 0) {
		// 			this.hasFollow = res.data
		// 		}
		// 	})
		// },
		// follow() {
		// 	if (this.hasFollow) {
		// 		deleteShopSubscribe({
		// 			site_id: this.goodsSkuDetail.site_id
		// 		}).then(res => {
		// 			if (res.code == 0 && res.data) {
		// 				this.hasFollow = !this.hasFollow
		// 				this.$notify({
		// 					message: "การยกเลิกสําเร็จ",
		// 					title: 'เรียบร้อยแล้ว',type: "success"
		// 				})
		// 			}
		// 		})
		// 	} else {
		// 		addShopSubscribe({
		// 			site_id: this.goodsSkuDetail.site_id
		// 		}).then(res => {
		// 			if (res.code == 0 && res.data) {
		// 				this.hasFollow = !this.hasFollow
		// 				this.$notify({
		// 					message: "มุ่งเน้นที่ความสําเร็จ",
		// 					title: 'เรียบร้อยแล้ว',type: "success"
		// 				})
		// 			}
		// 		})
		// 	}
		// },
		// โหลดรูปภาพไม่สําเร็จ
		imageErrorSpec(index) {
			this.goodsSkuDetail.sku_images[index] = this.defaultGoodsImage
			this.picZoomUrl = this.defaultGoodsImage
		},
		/**
		 * รับที่อยู่
		 * @param {Object} type
		 * @param {Object} item
		 * @param {Object} first ไม่ว่าจะเป็นครั้งแรก
		 */
		getAddress(type, item, first, callback) {
			let pid = 0
			switch (type) {
				case 'province':
					//โหลดจังหวัด
					pid = 0
					break
				case 'city':
					//โหลดเมือง
					if (item) {
						this.provinceId = item.id
					}
					pid = this.provinceId
					this.cityArr = {}
					this.districtArr = {}
					break
				case 'district':
					//โหลดเขต
					if (item) this.cityId = item.id
					pid = this.cityId
					this.districtArr = {}
					break
			}
			if (item) {
				if (item.level <= 2) {
					let len = item.level;
					for (let i = len; i <= 3; i++) {
						delete this.selectedAddress['level_' + i];
					}
				}
				this.selectedAddress['level_' + item.level] = item;
			}

			if (!first) this.$store.commit("app/SET_LOCATION_REGION", this.selectedAddress)
			this.$forceUpdate();
			if (type == 'community') {
				this.hideRegion = true;
				setTimeout(() => {
					this.hideRegion = false;
				}, 10);
				return;
			}

			getArea({
					pid: pid
				})
				.then(res => {
					const {
						code,
						data
					} = res;
					if (data) {
						switch (type) {
							case 'province':
								//โหลดจังหวัด
								this.provinceArr = data
								break
							case 'city':
								//โหลดเมือง
								this.cityArr = data
								break
							case 'district':
								//โหลดเขต
								this.districtArr = data
								break
						}
						this.currTabAddres = type

						if (callback) callback();
					}
				})
				.catch(err => {})
		}
	}
}
